// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import './add_jquery';
import './jquery-ui/jquery-ui.min.js';

import * as bootstrap from "bootstrap";
window.bootstrap = bootstrap;

import PerfectScrollbar from 'perfect-scrollbar';
window.PerfectScrollbar = PerfectScrollbar;

import cookies from 'js-cookie';
window.Cookies = cookies;

import 'pace-js';
//import "./color-admin6/app.min.js";
import "./color-admin6/app.js";

import "./controllers"

$(document).on("turbo:load", function() {

  gtag('config', 'G-J1VHVENGPM', {'page_location': event.detail.url});
})