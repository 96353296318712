import { Controller } from "@hotwired/stimulus"
//import $ from 'jquery';

import daterangepicker from "bootstrap-daterangepicker";
import moment from "moment";



// Connects to data-controller="pickers"
export default class extends Controller {
  connect() {
    
     $(".daterange").daterangepicker({
        opens: "right",
    //format: "YYYY-MM-DD",
    locale: {format: 'YYYY-MM-DD', cancelLabel: 'Clear'},
    separator: " to ",
    alwaysShowCalendars: true,
    showDropdowns: true,
    autoUpdateInput: false,
    //startDate: moment().subtract("days", 29),
    //endDate: moment(),
    ranges: {
           'Today': [moment(), moment()],
           'Yesterday': [moment().subtract('days',1), moment().subtract('days',1)],
           'Last 7 Days': [moment().subtract('days',6), moment()],
           'Last 30 Days': [moment().subtract('days',29), moment()],
           'This Month': [moment().startOf('month'), moment().endOf('month')],
           'Last Month': [moment().subtract('month',1).startOf('month'), moment().subtract('month',1).endOf('month')]
        }
  }, function (start, end) {
    //$(event.currentTarget) .val(start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY"));
  
      });


    $(".daterange").on('cancel.daterangepicker', function(ev, picker) {
      $(ev.currentTarget).val('')
    })

    $(".daterange").on('apply.daterangepicker', function(ev, picker) {
      $(this).val(picker.startDate.format('YYYY-MM-DD') + ' - ' + picker.endDate.format('YYYY-MM-DD'));
      //this is necessary because stimulus doesnt accept jquery events, just DOM events
      var event = new Event('focusout');
      document.getElementById(this.id).dispatchEvent(event)

  });

  }
}
