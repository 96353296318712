import { Controller } from "@hotwired/stimulus"

import select2 from "select2"

window.select2 = select2();


// Connects to data-controller="home"
export default class extends Controller {
 
  static targets = ["form"]

  connect() {

    let reload_frame = this.reload_frame;

    $(".select2").select2({});
    
    //select2 doesnt fire native events so select2 event is translated to native event so it works with stimulus action-change
    $("#city").on('select2:select', function () {
    
        
      //let event = new Event('change') // fire a native event
      //this.dispatchEvent(event);
      
      this.form.requestSubmit();
      reload_frame();
    });


    Turbo.navigator.delegate.adapter.showProgressBar();
    this.formTarget.requestSubmit();
    this.reload_frame();


    
  }
    
    search() {
      Turbo.navigator.delegate.adapter.showProgressBar();
     this.formTarget.requestSubmit();
     
    }

    reload_frame() {
      
      let frame_line_chart = document.querySelector('turbo-frame#home_line_chart')
      let frame_invoices_dashboard = document.querySelector('turbo-frame#invoices_dashboard')
      let frame_orders_dashboard = document.querySelector('turbo-frame#orders_dashboard')
      let frame_contracts_dashboard = document.querySelector('turbo-frame#contracts_dashboard')
      let home_map = document.querySelector('turbo-frame#home_map')
      let frame_invoicing_contractors_table = document.querySelector('turbo-frame#invoicing_contractors_table')

      let $year = $("#year").val();
      let $city = $("#city").val()
      let $currency = $("#currency").val()
      let $country = $("#country").val()
      
      frame_line_chart.src = ('home_line_chart/?city='+$city+'&year='+$year+'&country='+$country+'&currency='+$currency)
      frame_invoices_dashboard.src = ('invoices_dashboard/?city='+$city+'&year='+$year+'&country='+$country+'&currency='+$currency)
      frame_orders_dashboard.src = ('orders_dashboard/?city='+$city+'&year='+$year+'&country='+$country+'&currency='+$currency)
      home_map.src = ('home_map/?city='+$city+'&year='+$year+'&country='+$country+'&currency='+$currency)
      frame_contracts_dashboard.src = ('contracts_dashboard/?city='+$city+'&year='+$year+'&country='+$country+'&currency='+$currency)
      frame_invoicing_contractors_table.src = ('invoicing_contractors_table/?city='+$city+'&year='+$year+'&country='+$country+'&currency='+$currency)

      
      frame_line_chart.reload()
      frame_invoices_dashboard.reload()
      frame_orders_dashboard.reload()
      home_map.reload()
      frame_contracts_dashboard.reload()
      frame_invoicing_contractors_table.reload()
    }

}
