import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="search-form"
export default class extends Controller {
  
    static targets = ["form"]
    
    search() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        Turbo.navigator.delegate.adapter.showProgressBar();
        this.formTarget.requestSubmit()},400)
    }
  }
