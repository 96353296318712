import { Controller } from "@hotwired/stimulus"
//import $ from 'jquery';

import Feature from 'ol/Feature';
import OSM from 'ol/source/OSM';
import Map from 'ol/Map';
import Overlay from 'ol/Overlay';
import Point from 'ol/geom/Point';

import VectorSource from 'ol/source/Vector';
import View from 'ol/View';
import {Icon, Style, Text, Fill} from 'ol/style';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import {OverviewMap, defaults as defaultControls} from 'ol/control';



// Connects to data-controller="map"
export default class extends Controller {
  


  connect() {
    $(".bs-popover-top").remove()

    let $cities_data = JSON.parse($("#map").attr("data-cities"))
    let $locale = $("#map").attr("data-locale")



    function convertCoordinates(lon, lat) {
    var x = (lon * 20037508.34) / 180;
    var y = Math.log(Math.tan(((90 + lat) * Math.PI) / 360)) / (Math.PI / 180);
    y = (y * 20037508.34) / 180;
    return [x, y];
  }

  const vectorSource = new VectorSource({
  //features: [iconFeature],
  });

  for (var i=0;i<$cities_data.length;i++) {
    
  var iconFeature = new Feature({
  geometry: new Point(convertCoordinates(parseFloat($cities_data[i].long),parseFloat($cities_data[i].lat))),
  
  name: $cities_data[i].name,
  locale: $locale,
  county: $cities_data[i].county,
  url: $cities_data[i].slug_url,
  thumb: $cities_data[i].logo.thumb.url,
  iso2: $cities_data[i].iso2,
  international: $cities_data[i].international,
  slovak: $cities_data[i].slovak,
});

var iconStyle =  new Style({
  text: new Text({
   text: '\uf041',                         // fa-play, unicode f04b
    font: '900 18px "Font Awesome 5 Free"', // font weight must be 900
    fill: new Fill({
        color: '#c1272d'
      }),
  })
});

  iconFeature.setStyle(iconStyle);

  vectorSource.addFeature(iconFeature)

  }

  



var vectorLayer = new VectorLayer({
  source: vectorSource,
});


var map = new Map({
 // layers: [rasterLayer, vectorLayer],
 controls: defaultControls({ attribution: false }),
   layers: [
    new TileLayer({
      source: new OSM(),
    }),vectorLayer,
  ],
  target: document.getElementById('map'),
  view: new View({
    projection: 'EPSG:3857',
   // center: [0, 0],
   //center: [1874766.187075055,6167112.460353468],
  center:  convertCoordinates(16.8413112, 48.3609318),
    zoom: 4,
  }),
});

var element = document.getElementById('popup');

var popup = new Overlay({
  element: element,
  positioning: 'bottom-center',
  stopEvent: false,
});
map.addOverlay(popup);

// display popup on click
map.on('click', function (evt) {
  
  $(element).popover('dispose');
  
  var feature = map.forEachFeatureAtPixel(evt.pixel, function (feature) {
    return feature;
  });
  if (feature) {

  let $country_name = ''
  if ($("#active_locale").hasClass("fi-sk")) {
    $country_name = feature.get('slovak')
  }
  else {$country_name = feature.get('international')}

    popup.setPosition(evt.coordinate);
    $(element).popover({
      placement: 'top',
      html: true,
      content: ("<div class='text-center'> <img src='"+feature.get('thumb')+"' alt='logo' width='100' height='auto'><br/><a target='_blank' href='/"+feature.get('locale')+"/city/"+feature.get('url')+"'>"+feature.get('name')+"</a><br><p>"+$country_name+"</p></div>"),
    });
    $(element).popover('show');
  } else {
    $(element).popover('dispose');
  }
});

// change mouse cursor when over marker
map.on('pointermove', function (e) {
  var pixel = map.getEventPixel(e.originalEvent);
  var hit = map.hasFeatureAtPixel(pixel);
  map.getTarget().style.cursor = hit ? 'pointer' : '';
});
// Close the popup when the map is moved
map.on('movestart', function () {
  $(element).popover('dispose');
});


  }
}