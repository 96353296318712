import { Controller } from "@hotwired/stimulus"


// Connects to data-controller="home"
export default class extends Controller {
 
 
reload_frame() {
      
      let city_bar_chart = document.querySelector('turbo-frame#city_bar_chart')
      let $currency = $("#currency").val()
      let $city = $("#city_name").attr("data-city-id")
           
      city_bar_chart.src = ('/city_bar_chart/'+$city+'?currency='+$currency)
      city_bar_chart.reload()
      
    }

scroll_to_tab() {
      let tab = event.currentTarget.dataset.tab;
      $('.nav-tabs a[href="#' + tab + '"]').tab('show')
      $("a[href='#"+tab+"']").on('shown.bs.tab', function(e) {
            document.getElementById(tab).scrollIntoView()
      })

      event.preventDefault();
}

}
