import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="locales"
export default class extends Controller {
  connect() {
  }

    set_locale_cookie() {
      Cookies.set('openstats_city_locale', $.trim($(event.currentTarget).text()), { expires: 365 });
     
    }
}
