import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="search-result"
export default class extends Controller {
  connect() {
 
  }

  filter() {
    let keep_value;
    keep_value = $(event.currentTarget).attr('data-keep-value')
   // console.log(keep_value)

   if (keep_value == "all") {
     $.each($(".search-results"), function(index,value){
      $(value).removeClass('d-none')
     })
    }
    else {
    $.each($(".search-results"), function(index,value){
      //console.log(value)
     // console.log($(value).attr('id'))
      if ($(value).attr('id') == keep_value ) {$(value).removeClass('d-none') }
        else { $(value).removeClass('d-none').addClass('d-none') }
    })
  }
  }
}
