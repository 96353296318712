import { Controller } from "@hotwired/stimulus"
import { Modal } from 'bootstrap'


// Connects to data-controller="table"
export default class extends Controller {
  connect() {
  }

  
  populate_modal() {

    function fixJson(data) {
      data = data.replaceAll("\",","\",\n")
      data = data.replaceAll("\\","")

      return data
    }

    let $data
    $data = $(event.currentTarget).attr('data-object-source')
    $("#object_data_details").html(fixJson(JSON.stringify($data,null,2)))
    let myModal = new Modal('#data_object_modal')
    myModal.show()
    
  }
}

