import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["code"]

  connect() {
    
    this.originalContent = this.codeTarget.innerHTML
    this.shortContent = this.originalContent.slice(0, 30) + '...'
    this.expanded = false
    this.updateContent()
  }

  toggle() {
    this.expanded = !this.expanded
    this.updateContent()
  }

  updateContent() {
    this.codeTarget.innerHTML = this.expanded ? this.originalContent : this.shortContent
  }
}